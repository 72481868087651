// npm
import React from 'react'
import { graphql } from 'gatsby'

// components
import Layout from '@organisms/global/Layout'
import Sections from '@molecules/sections/Sections'

interface Props {
  data: Queries.DatoCmsHomepageQuery
  location: Location
}

const Homepage = ({ data }: Props) => {
  const d = data.datoCmsHomepage
  return (
    <Layout
      metadata={{
        seoTags: d.seoMetaTags,
      }}
      headerProps={{
        sizeVariant: 'normal',
        themeVariant: 'dark',
      }}
    >
      <Sections contentSections={d.homescreen} />
      <Sections contentSections={d.contentSections2} />
    </Layout>
  )
}

export default Homepage

export const pageQuery = graphql`
  query Homepage {
    datoCmsHomepage {
      homescreen {
        ...contentSectionVideoBackground
      }
      contentSections2 {
        ... on DatoCmsContentSectionContactBlock {
          ...contentSectionContact
        }
        ... on DatoCmsContentSectionImageTextBlock {
          ...contentSectionImageText
        }
        ... on DatoCmsContentSectionBgImageBlock {
          ...contentSectionBgImage
        }
        ... on DatoCmsContentSectionTwoImagesTextBlock {
          ...contentSectionTwoImagesText
        }
        ... on DatoCmsContentSectionHorizontalSliderBlock {
          ...contentSectionHorizontalSlider
        }
        ... on DatoCmsContentSectionBlogBlock {
          ...contentSectionBlog
        }
        ... on DatoCmsContentSectionImageTextBackgroundTextBlock {
          ...contentSectionImageTextBackgroundText
        }
        ... on DatoCmsContentSectionAutoTestimonialsBlock {
          ...contentSectionAutoTestimonials
        }
        ... on DatoCmsContentSectionRecentProjectsSwiper {
          ...contentSectionRecentProjects
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
