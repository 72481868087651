// npm
import React, { memo } from 'react'
import loadable from '@loadable/component'

// components
const ContentSectionVideoBG = loadable(
  () => import('./components/ContentSectionVideoBG')
)
const ContentSectionBgImage = loadable(
  () => import('./components/ContentSectionBgImage')
)
const ContentSectionContact = loadable(
  () => import('./components/ContentSectionContact')
)
const ContentSectionTextImage = loadable(
  () => import('./components/ContentSectionTextImage')
)
const ContentSectionTextTwoImages = loadable(
  () => import('./components/ContentSectionTextTwoImages')
)
const ContentSectionTextImageBackgroundText = loadable(
  () => import('./components/ContentSectionTextImageBackgroundText')
)
const ContentSectionSwiper = loadable(
  () => import('./components/ContentSectionSwiper')
)
const ContentSectionBlogPosts = loadable(
  () => import('./components/ContentSectionBlogPosts')
)
const ContentSectionAutoTestimonials = loadable(
  () => import('./components/ContentSectionAutoTestimonials')
)
const ContentSectionRecentProjects = loadable(
  () => import('./components/ContentSectionRecentProjects')
)

type Section =
  | Queries.DatoCmsContentSectionVideoBlock
  | Queries.DatoCmsContentSectionBgImageBlock
  | Queries.DatoCmsContentSectionContactBlock
  | Queries.DatoCmsContentSectionImageTextBlock
  | Queries.DatoCmsContentSectionTwoImagesTextBlock
  | Queries.DatoCmsContentSectionImageTextBackgroundTextBlock
  | Queries.DatoCmsContentSectionHorizontalSliderBlock
  | Queries.DatoCmsContentSectionBlogBlock
  | Queries.DatoCmsContentSectionAutoTestimonialsBlock
  | Queries.DatoCmsContentSectionRecentProjectsSwiper

export interface SectionsProps {
  contentSections: Section[]
  leadTextNoPaddingTop?: boolean
}

const Sections = ({ contentSections }: SectionsProps) => {
  return (
    <>
      {contentSections.map((block: Section) => {
        switch (block.model.apiKey) {
          case 'content_section_image_text_block': {
            const section = block as Queries.DatoCmsContentSectionImageTextBlock
            return (
              <ContentSectionTextImage
                key={`section-${section.id}`}
                sectionTextVariant="indented-text"
                keyId={section.id}
                mainHeading={section.mainHeadingNode.childMarkdownRemark.html}
                subtitle={section.smallHeading}
                text={section.textNode.childMarkdownRemark.html}
                button={section.button[0]}
                image={section.image.gatsbyImageData}
                imageStamp
              />
            )
          }
          case 'content_section_contact_block': {
            const section = block as Queries.DatoCmsContentSectionContactBlock
            return (
              <ContentSectionContact
                key={`section-${section.id}`}
                keyId={section.id}
                contactDetails={section.contactDetail}
              />
            )
          }
          case 'content_section_bg_image_block': {
            const section = block as Queries.DatoCmsContentSectionBgImageBlock
            return (
              <ContentSectionBgImage
                key={`section-${section.id}`}
                keyId={section.id}
                mainHeading={section.mainHeadingNode.childMarkdownRemark.html}
                subtitle={section.smallHeading}
                text={section.textNode.childMarkdownRemark.html}
                button={section.button[0]}
                imageLandscape={
                  section.backgroundImageLandscape.gatsbyImageData
                }
                imagePortrait={section.backgroundImagePortrait.gatsbyImageData}
                iconUrl={section.icon.url}
              />
            )
          }
          case 'content_section_two_images_text_block': {
            const section =
              block as Queries.DatoCmsContentSectionTwoImagesTextBlock
            return (
              <ContentSectionTextTwoImages
                sectionTextVariant="indented-text"
                key={`section-${section.id}`}
                keyId={section.id}
                mainHeading={section.mainHeadingNode.childMarkdownRemark.html}
                subtitle={section.smallHeading}
                text={section.textNode.childMarkdownRemark.html}
                button={section.button[0]}
                image={section.primaryImage.gatsbyImageData}
                secondaryImage={section.secondaryImage.gatsbyImageData}
              />
            )
          }
          case 'content_section_image_text_background_text_block': {
            const section =
              block as Queries.DatoCmsContentSectionImageTextBackgroundTextBlock
            return (
              <ContentSectionTextImageBackgroundText
                key={`section-${section.id}`}
                keyId={section.id}
                mainHeading={section.mainHeadingNode.childMarkdownRemark.html}
                subtitle={section.smallHeading}
                text={section.textNode.childMarkdownRemark.html}
                button={section.button[0]}
                image={section.image.gatsbyImageData}
                backgroundText={section.backgroundText}
              />
            )
          }
          case 'content_section_horizontal_slider_block': {
            const section =
              block as Queries.DatoCmsContentSectionHorizontalSliderBlock
            return (
              <ContentSectionSwiper
                key={`section-${section.id}`}
                keyId={section.id}
                mainHeading={section.mainHeadingNode.childMarkdownRemark.html}
                subtitle={section.smallHeading}
                text={section.textNode.childMarkdownRemark.html}
                slides={section.slides}
              />
            )
          }
          case 'content_section_blog_block': {
            const section = block as Queries.DatoCmsContentSectionBlogBlock
            return (
              <ContentSectionBlogPosts
                key={`section-${section.id}`}
                keyId={section.id}
                mainHeading={section.mainHeadingNode.childMarkdownRemark.html}
                subtitle={section.smallHeading}
                text={section.textNode.childMarkdownRemark.html}
              />
            )
          }
          case 'content_section_auto_testimonial_block': {
            const section =
              block as Queries.DatoCmsContentSectionAutoTestimonialsBlock
            return (
              <ContentSectionAutoTestimonials
                key={`section-${section.id}`}
                keyId={section.id}
                subtitle={section.smallHeading}
              />
            )
          }
          case 'content_section_video_block': {
            const section = block as Queries.DatoCmsContentSectionVideoBlock
            return (
              <ContentSectionVideoBG
                key={`section-${section.id}`}
                keyId={section.id}
                subtitle={section.smallHeading}
                mainHeading={section.mainHeadingNode.childMarkdownRemark.html}
                mainHeadingVariant="h1"
                text={section.textNode.childMarkdownRemark.html}
                videoUrl={section.videoUrl}
                button={section.button[0]}
              />
            )
          }
          case 'content_section_recent_projects_swiper': {
            const section =
              block as Queries.DatoCmsContentSectionRecentProjectsSwiper
            return (
              <ContentSectionRecentProjects key={`section-${section.id}`} />
            )
          }
          default: {
            return <></>
          }
        }
      })}
    </>
  )
}

export default memo(Sections)
